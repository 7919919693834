import React from "react"

const Author = ({ avatar, displayName, publishAt }) => {
  const { url } = avatar || {}
  return (
    <div className="author">
      <div className="author-basic">
        <div className="author-basic-avatar">
          <img src={url} />
        </div>
        <div className="author-basic-name-date">
          <span className="author-basic-name-date-name">{displayName}</span>
          <span className="author-basic-name-date-date">{publishAt}</span>
        </div>
      </div>
    </div>
  )
}

export default Author
