import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { FiUser } from "react-icons/fi"
import { Avatar } from "antd"
import gravatar from "gravatar"

import "./style.scss"
import { useSelector } from "react-redux"
import { logout } from "../../store/actions/user"

const logo = require("./assets/logo.png")

const Header = ({ siteTitle }) => {
  const dispatch = useDispatch()
  const { email, displayName, avatar = {} } = useSelector(state => state.user)
  const { inlineContent, bottomContent } = useSelector(state => state.header)
  const userAvatar = avatar && avatar.url ? avatar.url : gravatar.url(email)

  const logoutUser = () => {
    dispatch(logout())
  }

  const getHeader = e => {
    return (
      <header className="main-header">
        <div className="main-header-content">
          <Link
            to="/"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
          >
            <img className="site-logo" src={logo} alt={siteTitle} />
          </Link>

          <div className="main-header-content-inline">
            {inlineContent.content}
          </div>

          <span className="main-header-user-container">
            {displayName ? (
              <>
                <Link to={"/account"}>
                  <Avatar
                    size="small"
                    icon={userAvatar ? <img src={userAvatar} /> : <FiUser />}
                  />
                  <span className="display-name"> {displayName} </span>{" "}
                </Link>
                <span className="logout" onClick={logoutUser}>
                  · Logout
                </span>
              </>
            ) : (
              <Link to={"/account"}>
                <Avatar size="small" icon={<FiUser />} />
              </Link>
            )}
          </span>
        </div>
        <div className="main-header-content-bottom">
          {bottomContent.content}
        </div>
      </header>
    )
  }

  return <>{getHeader()}</>
}

export default Header
