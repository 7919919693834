import React, { useState, useEffect } from "react"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Skeleton } from "antd"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "./style.scss"

let Editor = () => (<div/>)
let htmlToDraft = () => null
let draftToHtml = () => null

if (typeof window !== "undefined") {
  Editor = require("react-draft-wysiwyg").Editor
  htmlToDraft = require("html-to-draftjs").default
  draftToHtml = require("draftjs-to-html")
}

const toolbarSettings = {
  options: ["inline", "remove"],
  inline: {
    options: ["bold", "italic", "underline", "strikethrough"]
  }
}

const EditorComponent = ({ content, placeholder, textOnly = false, singleLine = false, disableToolbar = false, disable = false, onChange }) => {
  const [loading, setLoading] = useState(true)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const toHtml = (es) => draftToHtml(convertToRaw(es.getCurrentContent())) // added

  useEffect(() => {

    if (content !== toHtml(editorState)) {
      setEditorState(
        EditorState.push(
          editorState,
          ContentState.createFromBlockArray(
            htmlToDraft(content || "")
          )
        )
      )
    }
    setLoading(false)
  }, [])


  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)

    if (textOnly) {
      return onChange(editorState.getCurrentContent().getPlainText())
    }

    const _html = toHtml(editorState)
    if (content !== _html) {
      onChange(_html)
    }
  }

  return loading ? <Skeleton active/> : (
    <Editor
      toolbarOnFocus
      toolbarHidden={!!disableToolbar}
      placeholder={placeholder}
      readOnly={disable}
      toolbar={toolbarSettings}
      editorState={editorState}
      wrapperClassName="editor-wrapper"
      editorClassName="editor-editor"
      handleReturn={() => singleLine}
      onEditorStateChange={onEditorStateChange}
    />
  )
}

export default EditorComponent