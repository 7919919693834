import uniqueId from "lodash/uniqueId"
import React, { useEffect, useState } from "react"
import useTraceUpdate from "./useTrace"
// import { getStore } from "../store"

export function useEditorComponentMethods(
  componentOnchange,
  props = { id: 0, validator: null, edit: false }
) {
  useTraceUpdate("editorComponentMethods", props)

  const { edit } = props
  const [state, setState] = useState({ hookId: uniqueId(), dummy: 0 })
  // const { dispatch } = getStore()

  const updateState = obj => {
    edit && setState({ ...state, ...obj })
  }

  const forceUpdate = () => {
    updateState({ dummy: state.dummy + 1 })
  }

  const componentMethods = () => ({ ...props, id: state.hookId, forceUpdate })
  //
  const updateCmpInStore = () => {
    edit && componentOnchange(componentMethods())
  }

  useEffect(() => {
    updateCmpInStore()
  }, [])

  return [state, updateState]
}
