import React, { useEffect, useState } from "react"
import moment from "moment"
import gravatar from "gravatar"

import { EditorBlock } from "draft-js"
import validatorUtil from "../../../../utils/validatorUtil"
import { useEditorComponentMethods } from "../../../../hooks/useEditorComponentMethods"
import { sanitize } from "../util/helper"
import Author from "./Author"

import "./postHeader.scss"
import ContentEditable from "../../editor/ContentEditable"

const PostHeader = props => {
  const [validator] = useState(() => validatorUtil())
  const {
    onChange,
    setMethods,
    title,
    author,
    created_at,
    edit = false,
  } = props
  useEditorComponentMethods(setMethods, { validator, edit })

  const handleChange = key => value => {
    onChange({ [key]: value })
  }

  const publishAt = moment(created_at || undefined).format("MMM D, YYYY")

  return (
    <div className={`editor-block post-header ${edit ? "edit" : ""}`}>
      {validator.message("title", title, "required")}
      <h1 className="title">
        <ContentEditable
          disabled={!edit}
          content={title}
          onChange={handleChange("title")}
          placeholder="Your awesome title"
          singleLine
          disableToolbar
          textOnly
        />
      </h1>

      <Author {...author} publishAt={publishAt} />
    </div>
  )
}

export default PostHeader
