import { clearComponents } from "../editor"

export const loginUser = ({ username, password } = {}) =>
  async (dispatch, getState, api) => {

    const { jwt, user } = await api.user.loginUser(username, password)

    if (jwt && user) {
      return dispatch(setUser({ ...user, jwt, username, password, loggedIn: true }))
    }
  }

export const logout = () => (dispatch, getState, api) => {
  dispatch(clearComponents())
  dispatch({ type: "LOGOUT_USER" })

  // window.location.reload()
}

export const registerUser = ({ recaptchaValue, paypalEmail = null, email, displayName, username, password }) =>
  async (dispatch, getState, api) => {

    const returnedUser = await api.user.registerUser({
      recaptchaValue,
      displayName,
      paypalEmail,
      email,
      username,
      password
    })

    if (returnedUser.user) {
      return await dispatch(loginUser({ username, password }))
    } else {
      return { fail: returnedUser.message || "Something went wrong, please try again later." }
    }
  }

export const getUserPosts = () => async (dispatch, getState, api) => {
  const posts = await api.user.getUserPosts(getState().user.id)
  dispatch(setUserPosts(posts))
  return posts
}


const setUser = (user) => (dispatch) => dispatch({ type: "SET_USER", payload: { user } })
export const setUserPosts = (posts) => (dispatch) => dispatch({ type: "SET_USER_POSTS", payload: { posts } })

