import React from "react"
import pickBy from "lodash/pickBy"
import PostImage from "../postImage"
import PostHeader from "../postHeader"
import Text from "../text"
import SocialCanvas from "../socialCanvas"

const typeToComponent = {
  "post-componenets.text": "text",
  "post-componenets.post-image": "post-image",
}

const componentsToData = {
  header: {
    type: "post-componenets.header",
    component: PostHeader,
    structure: PostHeader.structure,
  },
  "social-photo": {
    type: "post-social.social-photo",
    component: SocialCanvas,
    structure: SocialCanvas.structure,
  },
  "post-image": {
    type: "post-componenets.post-image",
    component: PostImage,
    structure: PostImage.structure,
  },
  text: {
    type: "post-componenets.text",
    component: Text,
    structure: Text.structure,
  },
}

export const componentToRaw = ({ component, data }) => {
  const componentMeta = componentsToData[component]
  const structureKeys = Object.keys(componentMeta.structure)
  const __component = componentMeta.type

  return { __component, ...pickBy(data, (v, k) => structureKeys.includes(k)) }
}

export const transformComponent = componentRaw => {
  const { __component } = componentRaw

  //Transform component type to React component
  const componentType = typeToComponent[__component] || Text
  return { componentType, data: { ...componentRaw } }
}

export const getComponentStructureData = componentType => {
  let cmpType = componentsToData[componentType]

  if (!cmpType) cmpType = componentsToData["text"]

  return {
    __component: cmpType.type,
    component: cmpType.component,
    data: cmpType.structure,
  }
}
