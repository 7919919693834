import uniqueId from "lodash/uniqueId"
import {
  getComponentStructureData,
  transformComponent,
} from "../../../components/post/components/util"
import PostHeader from "../../../components/post/components/postHeader"

export const setPost = (post = {}) => (dispatch, getState) => {
  const { title = null, socialPhoto = {}, content, created_at } = post || {}
  const { id, avatar, displayName, publishAt } = getState().user

  dispatch(clearComponents())

  dispatch(
    registerComponent({
      data: {
        title: title,
        author: { id, avatar, displayName, publishAt },
        created_at,
      },
      componentType: "header",
    })
  )

  dispatch(
    registerComponent({
      data: { ...socialPhoto.meta, uploaded: !!socialPhoto.image },
      componentType: "social-photo",
    })
  )

  if (post && content) {
    content.forEach(cmp => dispatch(registerComponent(transformComponent(cmp))))
  }

  dispatch({ type: "SET_POST", payload: { post } })
}

export const changeComponentOrder = (index, toIndex) => ({
  type: "CHANGE_COMPONENT_ORDER",
  payload: { index, toIndex },
})

export const disableEditor = (disabled = false) => ({
  type: "SET_EDITOR_DISABLE",
  payload: { disabled },
})

export const clearComponents = () => ({ type: "CLEAR_COMPONENTS" })

export const setComponentData = (componentId, data) => ({
  type: "UPDATE_COMPONENT_DATA",
  payload: {
    componentId,
    data,
  },
})

export const setComponentMethods = (componentId, methods) => ({
  type: "UPDATE_COMPONENT_METHODS",
  payload: {
    componentId,
    methods,
  },
})
export const removeComponent = componentId => ({
  type: "REMOVE_COMPONENT",
  payload: { componentId },
})

export const registerComponent = ({ data = {}, componentType = null }) => (
  dispatch,
  getState,
  api
) => {
  console.log("REGISTER", componentType, data)
  const componentId = uniqueId()
  return dispatch({
    type: "REGISTER_COMPONENT_IN_EDITOR",
    payload: {
      component: componentType,
      data: {
        ...data,
        componentId,
      },
    },
  })
}
