import React, { useEffect, useState, useRef } from "react"
import EditorComponent from "./editor"

const ContentEditable = props => {
  const container = useRef()

  const {
    className,
    content,
    onChange,
    placeholder,
    disableToolbar,
    textOnly = false,
    singleLine = false,
    disabled = false,
  } = props

  const scrollToRef = ref => {
    window.scrollTo({
      top: ref.current.getBoundingClientRect().top + window.scrollY,
      left: 0,
      behavior: "smooth",
    })
  }

  const getContainer = () => {
    const attr = {
      tabIndex: "-1",
      ref: container,
      className,
      onFocus: () => !disabled && scrollToRef(container),
    }

    return (
      <div {...attr}>
        <EditorComponent
          placeholder={placeholder}
          disable={disabled}
          content={content}
          onChange={onChange}
          disableToolbar={disableToolbar}
          singleLine={singleLine}
          textOnly={textOnly}
        />
      </div>
    )
  }

  return getContainer()
}

export default ContentEditable
