import React, { useEffect, useCallback, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import {
  LoadingOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
import Ratio from "react-ratio"
import Konva from "konva"
import { Group, Image, Layer, Rect, Stage, Text } from "react-konva"
import flattern from "lodash/flatten"
import "./style.scss"
import { dataURLtoFile } from "../util/helper"
import { uploadMedia } from "../../../../store/actions/posts"
import { useEditorComponentMethods } from "../../../../hooks/useEditorComponentMethods"
import useTraceUpdate from "../../../../hooks/useTrace"

const originalFillStroke = Konva.Context.prototype.fillStrokeShape
Konva.Context.prototype.fillStrokeShape = function (shape) {
  if (shape instanceof Konva.Text) {
    if (shape.getStrokeEnabled()) {
      this._stroke(shape)
    }
    if (shape.getFillEnabled()) {
      this._fill(shape)
    }
  } else {
    originalFillStroke.call(this, shape)
  }
}

const SocialCanvas = props => {
  const dispatch = useDispatch()
  const containerRef = useRef()
  const stageContainer = useRef()
  const [stateImages, setStateImages] = useState([])
  const [uploading, setUploading] = useState(false)
  const [size, setSize] = useState({ height: 281, width: 540 })
  const {
    onChange,
    setMethods,
    base64,
    image1,
    image2,
    image1Text,
    image2Text,
    imagesPosition = [],
    edit = false,
    uploaded = false,
  } = props

  useEditorComponentMethods(setMethods, {
    uploadImage: useCallback(uploadImage, []),
    edit,
  })
  useTraceUpdate("socialCanvas", props)

  useEffect(() => {
    if (containerRef.current) {
      const clientRec = containerRef.current.getBoundingClientRect()
      setSize({ height: clientRec.height, width: clientRec.width })
    }

    renderImagesInCanvas()
    updateState(true)
  }, [image1, image2, containerRef.current])

  const renderImagesInCanvas = () => {
    const devidedBy = image1 && image2 ? 2 : 1

    const imageLoading = [image1, image2].map(imageSrc => {
      if (!imageSrc) return

      return new Promise(resolve => {
        const image = new window.Image()
        image.src = imageSrc
        image.crossOrigin = "Anonymous"
        image.onload = () => {
          resolve(image)
        }
      })
    })

    Promise.all(imageLoading).then(images => {
      setStateImages(
        images
          .map((image, index) => {
            if (!image) return null

            const scale = Math.max(
              size.width / devidedBy / image.naturalWidth,
              size.height / image.naturalHeight
            )
            let xPosition = size.width / devidedBy
            if (index === 0) {
              xPosition =
                (image.naturalWidth * scale - size.width / devidedBy) * -1
            }

            return {
              x: imagesPosition[index] ? imagesPosition[index].x : xPosition,
              y: 0,
              height: image.naturalHeight * scale,
              width: image.naturalWidth * scale,
              image,
            }
          })
          .filter(x => !!x)
      )
    })
  }

  const getPosRect = (pos, ele) => {
    const cliPos = ele.getClientRect()
    return {
      left: pos.x,
      top: pos.y,
      right: pos.x + cliPos.width,
      bottom: pos.y + cliPos.height,
      width: cliPos.width,
      height: cliPos.height,
    }
  }

  async function uploadImage({ base64 }) {
    if (!base64) {
      return true
    }

    setUploading(true)

    const file = dataURLtoFile(base64, "social-photo")

    const uploadedImg = await dispatch(uploadMedia(file))
    console.log("uploadedImg", uploadedImg)

    if (uploadedImg.length > 0 && uploadedImg[0].url) {
      onChange({ base64: null, image: uploadedImg[0] })
      setUploading(false)
      return true
    }
    console.log("uploadedImg", uploadedImg)
    return false
  }

  const calcFontHeight = (text, fontSize, containerWidth) => {
    const span = document.createElement("span")

    span.innerText = text
    span.style.fontSize = fontSize + "px"
    span.style.display = "block"
    span.style.width = containerWidth + "px"
    span.style.fontFamily = "Arial"
    span.style.lineHeight = fontSize + "px"
    span.style.visibility = "hidden"

    document.querySelector("body").append(span)
    const height = span.clientHeight
    span.remove()

    return height + 10
  }

  const getImages = obj => {
    if (obj.className !== "Image") {
      if (obj.children) {
        const childrensSearch = obj.children.map(getImages).filter(x => !!x)
        return childrensSearch.length > 0 ? flattern(childrensSearch) : null
      }
    } else {
      return obj
    }
  }

  const updateState = (keepImage = false) => {
    if (edit && stageContainer.current) {
      const base64 = stageContainer.current.getStage().toDataURL()

      const imagesPosition = getImages(
        stageContainer.current.getStage()
      ).map(image => JSON.parse(JSON.stringify(image.attrs)))
      const keepImageObj = !keepImage ? { image: null } : {}
      onChange({ base64, imagesPosition, ...keepImageObj })
    }
  }

  const [firstImage, lastImage] = stateImages

  return (
    <div className="social-preview">
      <span className="describe-text">Social media image</span>
      <div className="inputs-container">
        <div>
          <labe for="firstImage">First image caption:</labe>
          <input
            name="firstImage"
            onInput={e => onChange({ image1Text: e.target.value })}
            value={image1Text}
            disabled={!edit}
          />
        </div>
        {lastImage && (
          <div>
            <labe htmlFor="secondImage">Second image caption:</labe>
            <input
              name="secondImage"
              onInput={e => onChange({ image2Text: e.target.value })}
              value={image2Text}
              disabled={!edit}
            />
          </div>
        )}
      </div>
      <Ratio
        ratio={540 / 281}
        style={{ maxWidth: "540px", maxHeight: "281px" }}
      >
        <div className="canvas-container" ref={containerRef}>
          <Stage ref={stageContainer} width={size.width} height={size.height}>
            <Layer draggable>
              <Group
                clipX={0}
                clipY={0}
                clipHeight={size.height}
                clipWidth={size.width / stateImages.length}
              >
                <Image
                  {...firstImage}
                  draggable
                  dragBoundFunc={function (pos) {
                    const imageRec = getPosRect(pos, this)
                    const { width, height, left, right, bottom, top } = imageRec

                    const _x =
                      right < size.width / stateImages.length
                        ? size.width / stateImages.length - width
                        : left > 0
                        ? 0
                        : pos.x
                    const _y =
                      stateImages.length > 1
                        ? 0
                        : bottom < size.height
                        ? size.height - height
                        : top > 0
                        ? 0
                        : pos.y

                    return {
                      x: _x,
                      y: _y,
                    }
                  }}
                  onDragEnd={updateState}
                />
              </Group>
            </Layer>
            <Layer>
              <Text
                x={0}
                y={
                  size.height -
                  calcFontHeight(
                    image1Text,
                    35,
                    size.width / stateImages.length - 10
                  )
                }
                verticalAlign="bottom"
                width={size.width / stateImages.length}
                align="center"
                text={image1Text}
                fontSize="35"
                fontStyle="bold"
                zIndex="999"
                stroke="black"
                strokeWidth="5"
                fill="white"
                margin={5}
              />
            </Layer>
            {lastImage && (
              <>
                <Layer>
                  <Group
                    clipX={size.width / 2}
                    clipY={0}
                    clipHeight={size.height}
                    clipWidth={size.width / 2}
                  >
                    <Image
                      {...lastImage}
                      draggable
                      dragBoundFunc={function (pos) {
                        const imageRec = getPosRect(pos, this)
                        const { width, left, right } = imageRec

                        const _x =
                          right < size.width
                            ? size.width - width
                            : left > size.width / 2
                            ? size.width / 2
                            : pos.x

                        return {
                          x: _x,
                          y: 0,
                        }
                      }}
                      onDragEnd={updateState}
                    />
                  </Group>
                </Layer>
                <Layer>
                  <Text
                    x={size.width / stateImages.length}
                    y={
                      size.height -
                      calcFontHeight(
                        image2Text,
                        35,
                        size.width / stateImages.length - 10
                      )
                    }
                    verticalAlign="bottom"
                    width={size.width / stateImages.length}
                    align="center"
                    text={image2Text}
                    fontSize="35"
                    fontStyle="bold"
                    zIndex="999"
                    stroke="black"
                    strokeWidth="5"
                    fill="white"
                    margin={5}
                  />
                </Layer>
              </>
            )}

            {lastImage && (
              <Layer>
                <Rect
                  x={size.width / 2}
                  y={0}
                  width={4}
                  height={size.height}
                  fill="white"
                  shadowBlur={10}
                  zIndex={2}
                />
              </Layer>
            )}
          </Stage>
        </div>
      </Ratio>
      <div className="upload-container">
        <span className="uploading-status">
          {uploaded && !base64 ? (
            <>
              <CheckCircleOutlined />
              <span>Uploaded</span>
            </>
          ) : base64 && uploading ? (
            <>
              <LoadingOutlined />
              <span>Uploading...</span>
            </>
          ) : base64 ? (
            <>
              <ClockCircleOutlined />
              <span>Waiting for upload</span>
            </>
          ) : null}
        </span>
      </div>
    </div>
  )
}
SocialCanvas.structure = {
  image: {},
  image1Text: "",
  image2Text: "",
  image1: null,
  image2: null,
  imagesPosition: {},
}
export default SocialCanvas
