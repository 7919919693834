import React, { useEffect, useState } from "react"

import ContentEditable from "../../editor/ContentEditable"
import { htmlToText, sanitize } from "../util/helper"
import { useEditorComponentMethods } from "../../../../hooks/useEditorComponentMethods"
import validatorUtil from "../../../../utils/validatorUtil"

const Text = props => {
  const [validator] = useState(() => validatorUtil())
  const { text, onChange, setMethods, edit = false } = props
  useEditorComponentMethods(setMethods, { validator, edit })

  const handleChange = key => value => {
    onChange({ [key]: value })
  }

  return (
    <div className={`editor-block paragraph ${edit ? "edit" : ""}`}>
      {validator.message("text", htmlToText(text), "required")}
      <ContentEditable
        disabled={!edit}
        content={text}
        onChange={handleChange("text")}
        placeholder="Paragraph text"
      />
    </div>
  )
}

Text.structure = { text: null }

export default Text
