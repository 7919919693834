import React, { useCallback, useMemo } from "react"
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { useDropzone } from "react-dropzone"

const activeStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

const ImageSelect = ({ onFileChanged, loading = false }) => {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log(acceptedFiles)
    if (acceptedFiles.length > 0 && onFileChanged)
      onFileChanged(acceptedFiles[0])
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/*",
  })

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  return (
    <div className="image-selector" {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <PlusOutlined />
      {isDragActive ? (
        <p>Drop the image here :)</p>
      ) : (
        <p>Drag 'n' drop selected image here, or click to select image</p>
      )}
    </div>
  )
}

export default ImageSelect
