import React, { useState, useCallback } from "react"
import {
  LoadingOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons"
import { useDispatch } from "react-redux"
import { AiOutlineClose } from "react-icons/ai"
import ImageSelect from "./ImageSelect"
import validatorUtil from "../../../../utils/validatorUtil"
import ContentEditable from "../../editor/ContentEditable"

import "./postImage.scss"
import {
  dataURLtoFile,
  getImageBase64,
  htmlToText,
  imageUploadValidator,
} from "../util/helper"
import { useEditorComponentMethods } from "../../../../hooks/useEditorComponentMethods"
import { uploadMedia } from "../../../../store/actions/posts"
import useTraceUpdate from "../../../../hooks/useTrace"

const PostImage = props => {
  const {
    title,
    description,
    image,
    creditText,
    creditUrl,
    imageObj,
    edit = false,
    onChange,
    setMethods,
  } = props

  const [validator] = useState(() =>
    validatorUtil({
      messages: {
        image: "You must select an image",
      },
    })
  )

  const dispatch = useDispatch()
  const [uploading, setUploading] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useEditorComponentMethods(setMethods, {
    validator,
    uploadImage: useCallback(uploadImage, []),
    edit,
  })
  useTraceUpdate("postImage", props)

  const handleChange = key => value => {
    onChange({ [key]: value })
  }

  async function uploadImage({ imageObj, image }) {
    if (!imageObj) {
      return !!image
    }

    setUploading(true)

    const file = dataURLtoFile(imageObj.base64, imageObj.name)

    const uploadedImg = await dispatch(uploadMedia(file, setUploadProgress))

    if (uploadedImg.length > 0 && uploadedImg[0].url) {
      onChange({ imageObj: null, image: uploadedImg[0] })
      setUploading(false)
      return true
    }

    return false
  }

  const getImageControllers = () => {
    return (
      <ul className="image-actions">
        <li
          onClick={() => {
            onChange({ image: null, imageObj: null })
          }}
        >
          <AiOutlineClose />
          <div>Remove</div>
        </li>
      </ul>
    )
  }

  const getImageView = () => {
    const imageUrl =
      image && image.url
        ? image.url
        : imageObj && imageObj.base64
        ? imageObj.base64
        : null
    if (imageUrl) {
      return (
        <>
          <div className={`image-container ${uploading ? "uploading" : ""}`}>
            {edit && getImageControllers()}
            <img className="image-dom" src={imageUrl} alt={htmlToText(title)} />
            {uploading && (
              <span className="loadingSpinner">
                {" "}
                <LoadingOutlined />
              </span>
            )}
            {edit && (
              <span className="uploadingStatus">
                {image && !imageObj ? (
                  <>
                    <CheckCircleOutlined />
                    <span>Uploaded</span>
                  </>
                ) : imageObj && uploading ? (
                  <>
                    <LoadingOutlined />
                    <span>Uploading...</span>
                  </>
                ) : imageObj ? (
                  <>
                    <ClockCircleOutlined />
                    <span>Waiting for upload</span>
                  </>
                ) : null}
              </span>
            )}
          </div>
        </>
      )
    }

    return (
      <ImageSelect
        onFileChanged={file => {
          if (!imageUploadValidator(file)) {
            alert("Image must be a png/jpeg and not exceed 4MB")
          } else {
            // validator.hideMessageFor("imageSize")

            getImageBase64(file, base64 => {
              onChange({ imageObj: { ...file, base64 } })
            })
          }
        }}
      />
    )
  }

  const getImageCredit = () => {
    if (!edit && creditText) {
      if (creditUrl) return <a href={creditUrl}>{creditText}</a>

      return creditText
    }
    return (
      <ContentEditable
        content={creditText || ""}
        onChange={handleChange("creditText")}
        placeholder="Image source credit"
        disabled={!edit}
        singleLine
        disableToolbar
        textOnly
      />
    )
  }

  return (
    <div className={`editor-block post-image ${edit ? "edit" : ""}`}>
      {validator.message("image", image || imageObj, "required")}
      {validator.message("title", htmlToText(title), "required")}
      {validator.message("credit", creditText, "required|string")}
      {validator.message("creditUrl", creditUrl, "href")}

      <h3 className="title">
        <ContentEditable
          content={title || ""}
          onChange={handleChange("title")}
          placeholder="Image title"
          disabled={!edit}
          singleLine
        />
      </h3>
      <div className="image">
        {getImageView()}

        <span className="image-credit--container">
          <div className="image-credit">
            <span>©</span>
            {getImageCredit()}
          </div>
          <div className="image-credit-url">
            {edit && (
              <ContentEditable
                content={creditUrl || ""}
                onChange={handleChange("creditUrl")}
                placeholder="Link to credits (Optional)"
                singleLine
                disableToolbar
                textOnly
              />
            )}
          </div>
        </span>
      </div>
      <span className="description">
        {(edit || description) && (
          <ContentEditable
            disabled={!edit}
            content={description}
            onChange={handleChange("description")}
            placeholder="Image description"
          />
        )}
      </span>
    </div>
  )
}

PostImage.structure = {
  title: null,
  description: null,
  image: null,
  creditText: null,
  creditUrl: null,
}

export default PostImage
