import React from "react"
import SimpleReactValidator from "simple-react-validator"
import { AiOutlineCloseCircle } from "react-icons/ai"
import {
  dataURLtoFile,
  imageUploadValidator,
} from "../components/post/components/util/helper"

function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ) // fragment locator
  return !!pattern.test(str)
}

export default (options = {}) => {
  const validator = new SimpleReactValidator({
    element: message => (
      <div className="editor-validation-error">
        <AiOutlineCloseCircle />
        <span>{message}</span>
      </div>
    ),
    validators: {
      href: {
        // name the rule
        message:
          "The :attribute must be a URL address, please insert an http/https link.",
        rule: (val, params, validator) => {
          return !val || validURL(val)
        },
        // messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
        required: true, // optional
      },
    },
    ...options,
  })

  return validator
}
