import sanitizeHtml from "sanitize-html"

export const disableReturnKey = {
  onKeyPressCapture: e => {
    if (e.key === "Enter") {
      e.preventDefault()
    }
  },
}

export const imageUploadValidator = file => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  const isLt5M = file.size / 1024 / 1024 < 5

  return isJpgOrPng && isLt5M
}

export const htmlToText = html => {
  const span = document.createElement("span")
  span.innerHTML = html

  return span.innerText || span.textContent
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const getImageBase64 = (img, callback) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

export const sanitize = (
  input,
  options = { allowedTags: [], allowedAttributes: {} }
) => {
  const sanitizeConf = {
    allowedTags: ["b", "i", "strong", "u", "p", "ins", "em", "del"],
    exclusiveFilter: function (frame) {
      return !frame.text.trim()
    },
  }

  if (!input || input.length === 0) return null

  return sanitizeHtml(input, sanitizeConf)
}
