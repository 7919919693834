import { loginUser, setUserPosts } from "../user"
import { convertComponentsToRaw } from "../../../private-pages/editPage/content-helper"

export const createPost = _post => async (dispatch, getState, api) => {
  const { components } = getState().editor
  const data = convertComponentsToRaw(components)

  return await api.posts.createPost(data)
}

export const uploadMedia = (fileObj, percentageCallback) => async (
  dispatch,
  getState,
  api
) => {
  return await api.media.uploadFile(fileObj, {
    onUploadProgress: progressEvent => {
      const totalLength = progressEvent.lengthComputable
        ? progressEvent.total
        : progressEvent.target.getResponseHeader("content-length") ||
          progressEvent.target.getResponseHeader(
            "x-decompressed-content-length"
          )
      console.log("onUploadProgress", totalLength)
      if (totalLength !== null && percentageCallback) {
        percentageCallback(
          Math.round((progressEvent.loaded * 100) / totalLength)
        )
      }
    },
  })
}

export const getPostById = postId => async (dispatch, getState, api) => {
  const _post = await api.posts.getPostById(getState().user.id, postId)
  if (_post.length === 1) {
    return _post[0]
  } else {
    return {
      fail: _post.message || "Something went wrong, please try again later.",
    }
  }
}

export const updatePost = () => async (dispatch, getState, api) => {
  const {
    components,
    post: { id: postId },
  } = getState().editor
  const data = convertComponentsToRaw(components)

  return await api.posts.updatePost(postId, data)
}
