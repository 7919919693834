import each from "lodash/each"
import map from "lodash/map"
import { componentToRaw } from "../../components/post/components/util"

export const validateComponents = componentMethods => {
  let isAllValid = true
  each(componentMethods, ({ validator = null, forceUpdate }) => {
    if (!validator) return

    validator.hideMessages()
    if (!validator.allValid()) {
      console.log(validator.getErrorMessages())
      isAllValid = false
      validator.showMessages()
      forceUpdate()
    }
  })

  return isAllValid
}

export const uploadImages = async (components, componentMethods) => {
  const promises = []

  each(components, ({ component, data }, index) => {
    console.log(component, typeof componentMethods[index].uploadImage)
    if (
      componentMethods[index] &&
      typeof componentMethods[index].uploadImage === "function"
    ) {
      promises.push(componentMethods[index].uploadImage(data))
    }
  })

  return await Promise.all(promises)
}

export const convertComponentsToRaw = components => {
  const [header, socialPhoto, ..._components] = components
  const { image, base64, ...meta } = componentToRaw(socialPhoto)

  let returnObj = {
    ...header.data,
    socialPhoto: {
      image,
      meta,
    },
    content: map(_components, componentToRaw),
  }
  console.log(returnObj)
  return returnObj
}
