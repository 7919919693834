/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import "./layout.scss"

const Layout = ({ name, children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title}/>
      <main className={`page ${name}`}>{children}</main>
      <footer>
        © {new Date().getFullYear()}, All rights reserved
        {` `}
        <a href="https://www.flurr.me">Flurr.me</a>
      </footer>
    </>
  )
}

Layout.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired
}

Layout.defaultPropTypes = {
  name: "layout"
}

export default Layout
